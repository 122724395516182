import { gql } from "@apollo/client";

export const addFeatureFlag = gql`
  mutation addFeatureFlag($featureFlagType: FeatureFlagType!, $brand: String!, $username: String, 
    $os: String, $featureId: String!, $enabled: Boolean!, $featureName: String, $description: String) {
    addFeatureFlag(input:{
        featureFlagType: $featureFlagType,
        brand: $brand,
        username: $username,
        os: $os
        feature: {
            featureId: $featureId,
            enabled: $enabled,
            name: $featureName,
            description: $description
        }
      }) {
        success
      }
}
`;

export const updateFeatureFlag = gql`
  mutation updateFeatureFlag($featureFlagType: FeatureFlagType!, $brand: String!, $username: String, 
    $os: String, $featureId: String!, $enabled: Boolean!, $featureName: String, $description: String) {
    updateFeatureFlag(input:{
        featureFlagType: $featureFlagType,
        brand: $brand,
        username: $username,
        os: $os
        feature: {
            featureId: $featureId,
            enabled: $enabled,
            name: $featureName,
            description: $description
        }
      }) {
        success
      }
}
`;

export const getFeatureFlags = gql`
    query getFeatureFlags($featureFlagType: FeatureFlagType!, $brand: String!, $username: String, 
        $os: String) {
        getFeatureFlags(input:{
        featureFlagType: $featureFlagType,
        brand: $brand,
        username: $username,
        os: $os
        }) {
            features {
                featureId
                enabled
                name
                description
            }
        }
    }
`;

export const deleteFeatureFlag = gql`
mutation deleteFeatureFlag($featureFlagType: FeatureFlagType!, $brand: String!, $username: String, 
    $os: String, $featureId: String!) {
    deleteFeatureFlag(input:{
        featureFlagType: $featureFlagType,
        brand: $brand,
        username: $username,
        os: $os,
        featureId: $featureId
        }) {
        success
    }
}
`; 